body {
  background-color: #d7ccc8;

  .login {
    height: 1000px;
    width: 100%;
    position: relative;

    .loginBox {
      width: 1050px;
      height: 600px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      border-radius: 15px;
      box-shadow: 1px 4px 22px -8px #0004;
      display: flex;
      overflow: hidden;

      .left {
        width: 55%;
        background-color: #ebebeb;
        text-align: center;

        .logo-container img {
          max-width: 100%;
          height: 670px;
        }
      }

      .right {
        width: 45%;
        height: 100%;
        justify-content: center;
        text-align: center;

        .contact {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 75%;
          margin: auto;
        }

        .contact h2 {
          text-align: center;
          margin-bottom: 40px;
        }

        .contact p {
          text-align: center;
          margin-top: 20px;
        }

        .contact input {
          border: 1px solid rgba(240, 203, 192, 0.918);
          border-radius: 8px;
          width: 80%;
          margin: 15px 0px 15px 0px;
          // border-bottom: 1px solid #4f30677d;
          padding: 10px 15px 10px;
          width: 70%;
          overflow: hidden;
          background: transparent;
          font-weight: 600;
          font-size: 16px;
        }

        .togglePassword {
          position: absolute;
          //top: 50%;
          right: 120px;
          bottom: 246px;
          cursor: pointer;
        }

        .submitBtn {
          border: none;
          padding: 15px 50px;
          border-radius: 12px;
          display: block;
          margin: auto;
          margin-top: 40px;
          background: #ff4e16;
          color: #fff;
          font-weight: bold;
          font-size: 18px;
          -webkit-box-shadow: 0px 9px 15px -11px rgba(88, 54, 114, 1);
          -moz-box-shadow: 0px 9px 15px -11px rgba(88, 54, 114, 1);
          box-shadow: 0px 9px 15px -11px rgba(88, 54, 114, 1);
        }
      }
    }
  }
}
