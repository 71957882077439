/* Card Styles */
.card {
  overflow: hidden;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 450px;
  cursor: pointer;
  transition: transform 200ms ease-in;
}

/* CardBody Styles */
.cardBody {
  /* Add styles for CardBody here if needed */
}

/* CardTitle Styles */
.cardTitle {
  padding: 1rem;
  text-align: center;
  font-size: 28px;
}

/* CardImg Styles */
.cardImg {
  height: 12rem;
  width: 100%;
  object-fit: contain;
  margin-top: 10px;
}

/* CardDesc Styles */
.cardDesc {
  padding: 0 1rem;
  text-align: center;
}

/* CardBtn Styles */
.cardBtn {
  padding: 1rem;
  font-weight: bold;
  font-size: 1rem;
  margin: 1rem;
  border: 2px solid;
  background-color: transparent;
  border-radius: 10px;
  cursor: pointer;
  color: #ff5722;
}
