@import "../../assets/styles/variables.scss";

.planogramContainer {
  width: 100%;
  max-width: 1024px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
  box-sizing: border-box;
  // overflow: auto;

  .planogramHeader {
    width: 100%;
    background-color: $softGrey;
    height: 110px;
    padding: 10px;

    .planoTitle {
      font-size: 20px;
      color: $dark;
      font-weight: 600;

      #store {
        color: $primary;
      }
    }

    .dropdownBtns {
      display: flex;
      flex-direction: row;
    }
  }
}

.planogramDropdownBtn {
  padding: 10px 20px;
  margin-right: 5px;
  color: $dark;
  background-color: $light;
  outline: none;
  border: 1px solid;
  border-color: $primary;
  border-radius: $borderRadius;
  cursor: pointer;
  font-weight: 700;

  &:disabled {
    background-color: $btnDisabled;
    border-color: $btnDisabled;
    cursor: default;
  }
}
