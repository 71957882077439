@import "../../assets/styles/variables.scss";

.infoContainer {
  display: grid;
  z-index: 1;
  height: 500px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;

  .infoRow {
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: "col2 col1";

    @media screen and (max-width: 768px) {
      grid-template-areas: "col1 col2";
    }

    .infoRow-column1 {
      margin-bottom: 15px;
      padding: 0 15px;
      grid-area: col1;

      .textWrapper {
        max-width: 540px;
        padding-top: 0;
        padding-bottom: 60px;
      }

      .topline {
        color: #01bf71;
        font-size: 16px;
        line-height: 16px;
        font-weight: 700;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        margin-bottom: 16px;
      }

      h1 {
        margin-bottom: 24px;
        font-size: 48px;
        line-height: 1.1;
        font-weight: 600;
        color: #010606;

        @media screen and (max-width: 480px) {
          font-size: 32px;
        }
      }

      .subtitle {
        max-width: 440px;
        margin-bottom: 35px;
        font-size: 18px;
        line-height: 24px;
        color: #010606;
      }
    }

    .infoRow-column2 {
      max-width: 540px;
      padding-top: 0 15px;
      padding-bottom: 60px;
      grid-area: col2;

      img {
        width: 100%;
        margin: 0 0 4rem 0;
        padding-right: 0;
      }
    }
  }
}
