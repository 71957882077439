@import "./variables.scss";
@import "~bootstrap/scss/bootstrap";

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: $Poppins;
  width: 100%;
  color: $dark;
}

#root {
  height: 100%;
}
