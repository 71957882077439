.exportsTitle {
  font-size: 21px;
  margin-bottom: 1.5rem;
}

.exportsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //height: 100vh;
  width: 100%;
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
}

.exportsCards {
  display: flex;
  flex-direction: row;
  //flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}
