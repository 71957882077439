@import "../../assets/styles/variables.scss";

.myFooter {
  background: $dark;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 10vh;
  padding: 0 1.5rem;
  @include breakpoint(md) {
    padding: 0 3rem;
  }

  .footer-content {
    overflow: hidden;
    color: $light;
    margin: 0 auto;
    max-width: 1920px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 100;

    .footer-logo {
      font-size: 1.3rem;
      font-weight: bold;
      text-decoration: none;
      color: #fff;
      cursor: pointer;

      &:hover {
        color: $primary;
      }
    }

    .footer-rights {
      font-size: 0.8rem;
      color: #fff;
      justify-content: center;
      align-items: center;
      margin-top: 35px;
    }

    .footer-social {
      top: 0;
      right: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;
      margin-top: 20px;
      justify-content: space-between;

      a {
        color: #fff;
        font-size: 22px;
        margin: 0 0.5rem;

        &:hover {
          color: $primary;
        }
      }
    }
  }
}
