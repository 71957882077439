@import "../../assets/styles/variables.scss";
/*
.modal-header {
  flex-direction: column;
  align-items: baseline;
}
*/

.modal-body {
  //display: flex; //deactivated because of editProductModal issues
}

.bestSuggested {
  padding: 1.5rem;
  //display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: left;
  //flex: 1; /* Take up remaining available space (on the left)*/

  #productPreview {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .thumbnailCell {
    width: 21vw;
    height: auto;
    margin: auto;
  }

  .formElement {
    margin: 1rem 0 1rem 0;
  }
}

.editProductSub {
  //background-color: lime;
}

.modal-dialog {
  max-width: 42%; //69vw;
}

.listItem {
  margin: 2px 0 2px 0;
}

.listItem.selected {
  background-color: green;
  color: white; /* optional: change text color for better visibility */
}

#cvLists {
  display: flex;
  flex: 1; /* Take up remaining available space (on the right)*/
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .cvList {
    width: 100%;
    margin: 0 0 1rem 0;
  }
}

.confidence {
  //font-size: 1.2vw;
  font-style: italic;
}

#productTitle {
  //color: #ff4e16;
  font-size: 1.5vw;
  font-weight: 100;
  font-style: normal;
  text-transform: lowercase;
}

#productTitleInfo {
  color: #ff4e16;
  font-style: italic;
  text-transform: lowercase;
}

#masterDropdown {
  width: 100%; // 50% to center it;
  margin: auto;

  .dropdown {
    width: 100%;
  }
}

.modal-footer {
  z-index: 99;
}

#editProductsModal {
  max-width: 69vw;
}

#editProductsWrapper {
  #editUserModal {
    display: flex;
    flex-direction: row;
    // flex: 1;
  }
}

.form-control {
  width: 64%;
}

@media (max-width: 768px) {
  .modal-dialog {
    max-width: 90vw; /* Adjust the max-width for smaller screens */
  }
}

.placeholderModal {
  width: 90vw; /* Occupy the 90% of the screen width */
  max-width: 90vw;
}

#editProductTooltip {
  //z-index: 9000 !important;
}

#carouselButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1rem 0 1rem 0;

  .carouselButton {
    margin: 0 2rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: #fddfd5f6;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .carouselButton:hover {
    background-color: #ff4e16;
  }
}
