@import "../../assets/styles/variables.scss";

.myTableContainer {
  width: 100%;
  height: 70vh;
  max-width: 1250px;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
  box-sizing: border-box;
  overflow: auto;

  margin: 1vh auto 0 auto;

  .tableHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: rgb(240, 240, 240);

    .tableTitle {
      color: #ff4e16;
      font-size: 15px;
      font-weight: 700;
      // text-transform: lowercase;
    }

    .tableSearchWrapper {
      justify-content: flex-end;

      .tableInput {
        margin: 0 10px;
        padding: 5px 5px;
        outline: none;
        border: 1px solid #0298cf;
        border-radius: 6px;
        color: #0298cf;

        &:focus {
          opacity: 1;
          //color: black;
          box-shadow: 1px 1px 9px #1a8fbba3;
        }
      }
    }
  }

  .tableSection {
    height: 500px;
    overflow: auto;

    margin-bottom: 3rem;

    .tableWrap {
      width: 100%;
      table-layout: fixed;
      min-width: 1000px;
      border-collapse: collapse;

      thead {
        position: sticky;
        top: 0;
        background-color: #f6f9fc;
        color: #8493a5;
        font-size: 15px;
        z-index: 9;

        tr {
          &:hover {
            color: #0298cf;
            cursor: pointer;
            background-color: #f6f9fc;
          }

          th {
            border-bottom: 1px solid #dddddd;
            padding: 10px 10px;
            word-break: break-word;
            white-space: break-spaces;
            text-align: center;
          }
        }
      }

      tbody {
        // start of product image preview css //
        .thumbnailCell {
          position: relative;
          //overflow: hidden;
        }

        .thumbnail {
          transition: transform 0.3s ease-in-out;
        }

        .thumbnail:hover {
          transform: scale(2); /* Adjust the scaling factor as needed */
          z-index: 100;
        }
        // end of product image preview css //

        tr {
          &:hover {
            color: #0298cf;
            cursor: pointer;
            background-color: #f6f9fc;
          }
        }

        td {
          border-bottom: 1px solid #dddddd;
          padding: 10px 10px;
          word-break: break-word;
          white-space: break-spaces;
          text-align: center;

          button {
            padding: 10px;
            outline: none;
            border: none;
            border-radius: 6px;
            margin: 3px;
            cursor: pointer;
            color: #fff;

            &[action="edit"] {
              background: #976c60;
            }

            &[action="delete"] {
              background: #ff2400;
            }

            &[action="upload"] {
              background: #60d394;
            }

            &[action="startCV"] {
              background: #ff4e16;
            }

            &[action="cvRuns"] {
              background: grey;

              &:hover {
                opacity: 0.9;
              }
            }

            &[action="disabled"] {
              background: #d3d3d3;
            }

            &:hover {
              opacity: 0.9;
              color: black;
              box-shadow: 2px 2px 9px #333333;
            }
          }
        }
      }
    }
  }
  .tableFooter {
    margin: 6px 0 21px 0;
    // padding: 1rem;
    width: 100%;

    button {
      //padding: 1rem;
      width: 100%;
      height: 9vh;
    }
  }
}
